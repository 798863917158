:root {
  --white: #fff;
/*  --active-color: #00aeb0;*/
}

* {
  box-sizing: border-box;
}
/*
html {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  background: var(--active-color);
  transition: background 0.4s ease;
}*/
/*
button {
  background: none;
  border: none;
  cursor: pointer;
}
*/
/*.audio-player {
  max-width: 350px;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: var(--white);
}

.artwork {
  border-radius: 120px;
  display: block;
  margin: auto;
  height: 100px;
  width: 100px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}
*/
.title1 {
/*  font-weight: 300;*/
  margin-bottom: 4px;
}

.artist1 {
/*  font-weight: 100;*/
  margin-top: 0;
}