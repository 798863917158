body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  height: 100%;
  margin: 0;
}

#App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

nav{
  background-color: red;
  flex-basis: 50px;
}

main {
  flex: auto;
  display: flex;
  overflow: hidden;
}
/*
aside{
  background-color: yellow;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 30%;
}*/

footer {
  flex-basis: 50px;
  background-color: blue;
}

.grid-container {
/*  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;
  grid-auto-flow: row;
  grid-auto-rows: 100px;*/
  width: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: 100px;
}

.grid-container > .item {
  border: 2px solid black;
  display: flex;
  flex:auto;
}


.sidenav {
  width: 300px;
/*  position: fixed;*/
  z-index: 1;
/*  top: 20px;*/
/*  left: 10px;*/
  background: #eee;
  overflow-x: hidden;
  padding: 8px 0;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
/*  font-size: 25px;*/
  color: #2196F3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}